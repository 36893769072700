.wrapper {
  background-color: #f5f7fb;
}

.content {
  padding: 0 var(--spacing-1) var(--spacing-10);

  @media (--tablet-s) {
    padding: 0 var(--spacing-1) var(--spacing-12);
  }
  @media (--tablet-l) {
    padding: 0 var(--spacing-2) var(--spacing-16);
  }

  @media (--desktop-l) {
    padding: 0 var(--spacing-3) var(--spacing-16);
  }
}

.description {
  padding-bottom: var(--spacing-6);
}

.table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
}

.row {
  &[data-theme='contrast'] {
    &:first-child {
      background-color: var(--color-primary-600);
      color: var(--color-championship-white);
    }
  }
}

.cell {
  padding: var(--spacing-2);
  min-width: 180px;
  overflow-wrap: break-word;
}

.equalWidthCells {
  @media (--desktop) {
    width: 100%;
  }
}

.equalWidthCells tr {
  display: flex;
  padding: var(--spacing-8) var(--spacing-6);
  gap: var(--spacing-6);
}

.equalWidthCells tr:nth-child(odd) {
  background-color: rgb(231, 235, 245);
}

.equalWidthCells td {
  flex: 1;
}
