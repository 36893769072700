.wrapper {
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
  padding: var(--spacing-10) 0;

  @media (--tablet) {
    &:first-of-type {
      padding-top: var(--spacing-20);
    }
  }
  & h2 {
    margin-top: 0;
    @media (--tablet-l) {
      font-size: 32px;
      margin-bottom: var(--spacing-8);
    }
    @media (--desktop) {
      margin-bottom: var(--spacing-10);
    }
  }
}

.cards {
  display: grid;
  padding: 0;
  margin: 0;
  list-style: none;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: var(--spacing-2);

  @media (--tablet) {
    gap: var(--spacing-3);
  }

  @media (--tablet-l) {
    gap: var(--spacing-4);
  }

  @media (--desktop) {
    gap: var(--spacing-5);
  }

  & a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
  }
}

.reviews {
  background: var(--color-championship-white);
  border: 1px solid var(--Colour-Neutral-100, #e6e6e6);
  width: 323px;

  @media (--tablet) {
    flex-grow: 1;

    & > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}
